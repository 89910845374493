import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import { Helmet } from "react-helmet";
import { Flex, Box } from "@rebass/grid/emotion";

import { breadcrumbs } from "shared/helpers/schema";

import Card from "../../components/Card";
import Hero from "../../components/Hero";
import Page from "../../components/Page";
import Tile from "../../components/Tile";
import Kranicon from "../../components/Kranicon";
import { LinkButton } from "../../components/Button";
import PageBlock from "../../components/PageBlock";

const Finanzierung = ({ data }) => (
  <Page
    header={
      <Hero
        fluid={data.financingImage.fluid}
        icon={<Kranicon icon="finanzierung" />}
        title="Immobilienfinanzierung"
        intro="Kranich Immobilien berät Sie kompetent und unabhängig."
      />
    }
  >
    <Helmet
      title="Finanzierung"
      meta={[
        {
          name: "description",
          content:
            "Der Immobilienkauf zieht oft große Geldsummen mit sich, welche gründlich geprüft werden müssen. Wir unterstützen Sie bei der Finanzierungsplanung und stehen immer an Ihrer Seite.",
        },
      ]}
      script={[
        breadcrumbs({
          crumbs: [
            {
              name: "Finanzierung",
              link: "/finanzierung/",
              position: 1,
            },
          ],
          siteUrl: data.site.siteMetadata.siteUrl,
          siteTitle: data.site.siteMetadata.title,
        }),
      ]}
    />
    <PageBlock>
      <Flex flexWrap="wrap">
        <Box width={[1, 1 / 2]}>
          <Tile
            css={css`
              height: 100%;
            `}
          >
            <Card>
              <h2>Beratung</h2>
              <p>
                Gehören Sie auch zu den Menschen, für die sechsstellige Summen
                enorm hoch und kaum realisierbar erscheinen? Dann dürfte es
                Ihnen schwerfallen, festzustellen, wie hoch die Finanzierung für
                Ihr Traumhaus ausfallen darf und wie viel Immobilie Sie sich im
                Endeffekt leisten können. Für Immobilienfinanzierungen sind dies
                aber vollkommen normale Dimensionen, wobei den Preisen nach oben
                praktisch keine Grenzen gesetzt sind. Folglich ist es wichtig,
                dass Sie sich ein Limit setzen und eine Obergrenze definieren,
                um nicht Gefahr zu laufen, sich durch den Kauf eines Hauses oder
                einer Eigentumswohnung zu überschulden.
              </p>
            </Card>
          </Tile>
        </Box>
        <Box width={[1, 1 / 2]}>
          <Tile
            css={css`
              height: 100%;
            `}
          >
            <Card>
              <h2>Lösungen</h2>
              <p>
                Im Gegensatz zu einer Bank, die ausschließlich eigene Produkte
                anbietet, lohnt sich die Angebotsvielfalt verschiedener
                Bankpartner zu prüfen. 30 Bankpartner warten mit Angeboten.
              </p>
              <p>
                Grundsätzlich stehen in Zusammenhang mit der Finanzierung von
                Immobilien die folgenden Lösungen zur Auswahl:
              </p>
              <p>
                Annuitätendarlehen, Tilgungsdarlehen, Cap-Darlehen,
                Forward-Darlehen, Bausparverträge, Förderdarlehen
              </p>
            </Card>
          </Tile>
        </Box>
      </Flex>
      <Tile>
        <Card
          css={css`
            text-align: center;
          `}
        >
          <p>Fordern Sie jetzt Ihr persönliches Angebot an!</p>
          <p
            css={css`
              margin-bottom: 0;
            `}
          >
            <LinkButton to="/kontakt/">Kontakt</LinkButton>
          </p>
        </Card>
      </Tile>
    </PageBlock>
  </Page>
);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    financingImage: imageSharp(
      fluid: { originalName: { regex: "/financing/" } }
    ) {
      fluid(maxWidth: 1600, quality: 80) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

export default Finanzierung;
