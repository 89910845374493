import { Link } from 'gatsby'
import { space } from 'styled-system'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const buttonStyles = props => css`
	${space(props)};
	padding: 10px 30px;
	font-weight: 600;
	font-size: 16px;
	letter-spacing: .5px;
	cursor: pointer;
	border-radius: 100px;
	transition: .3s;
	border: none;
	${props.light ? css`
		background-color: #ffedf7;
		color: #970e52;
		&:hover { background-color: white; }
	` : css`
		color: white;
		background: linear-gradient(to right, #80176a 0%, #a6113c 100%);
		&:hover { filter: brightness(1.15); }
	`}
`

export default styled.button(buttonStyles)

export const LinkButton = styled(Link)`
	display: inline-block;
	${buttonStyles};
	text-decoration: none;
`
